
import { Component, Vue } from "vue-property-decorator";
import DeletePopover from "@/components/DeletePopover.vue";
import TheReport from "@/components/TheReport.vue";
import Notify from "@/utils/notifications";
import Lead from "@/models/Liink/Lead";
import _ from "underscore";
import Liink, { GetLeadsParams, LiinkResponse } from "@/api/Liink";
import Catalog from "@/models/Liink/Catalog";
import Lender from "@/models/Liink/Lender";

@Component({
  components: {
    DeletePopover,
    TheReport,
  },
})
export default class LeadView extends Vue {
  loading = true;
  report = false;
  defaultParams = {
    take: 10,
    skip: 0,
  };
  params: GetLeadsParams = _.clone(this.defaultParams);
  response: LiinkResponse<Lead> | null = null;
  currentPage = 1;
  limitArray = [10, 25, 50, 100, 250, 500, 1000];

  employmentStatus: Array<Catalog> = [];
  employmentTimeWorked: Array<Catalog> = [];
  cardTypes: Array<Catalog> = [];
  banks: Array<Catalog> = [];
  lenders: Array<Lender> = [];

  availableColumns = [
    {
      name: "Nombre",
      code: "name",
    },
    {
      name: "Email",
      code: "email",
    },
    {
      name: "Teléfono",
      code: "phone",
    },
    {
      name: "Fecha de nacimiento",
      code: "birthdate",
    },
    {
      name: "Género",
      code: "gender",
    },
    {
      name: "CLABE",
      code: "CLABE",
    },
    {
      name: "Banco",
      code: "bankId",
    },
    {
      name: "Código postal",
      code: "zipcode",
    },
    {
      name: "¿Puede comprobar ingresos?",
      code: "proofOfIncome",
    },
    {
      name: "Ingresos mensuales",
      code: "monthlyIncome",
    },
    {
      name: "Gastos mensuales",
      code: "monthlyExpenses",
    },
    {
      name: "Situación laboral",
      code: "statusId",
    },
    {
      name: "Antigüedad laboral/Negocio",
      code: "timeWorkedId",
    },
    {
      name: "¿Propietario de casa/departamento?",
      code: "propertyOwner",
    },
    {
      name: "Score",
      code: "score",
    },
    {
      name: "Préstamo",
      code: "loan",
    },
    {
      name: "Deuda",
      code: "debt",
    },
    {
      name: "Debe en",
      code: "institutionId",
    },
    {
      name: "Tarjeta",
      code: "card",
    },
    {
      name: "Ofertas",
      code: "offers",
    },
    {
      name: "Creado",
      code: "createdAt",
    },
  ];
  selectedColumns = this.availableColumns.map(column => column.code);

  selectAllColumns() {
    this.selectedColumns = _.pluck(this.availableColumns, "code");
  }

  inSelectedColumns(column: keyof GetLeadsParams) {
    const exist = _.contains(this.selectedColumns, column);
    return exist;
  }

  async getLeads(page = this.currentPage) {
    this.currentPage = page;
    this.loading = true;

    try {
      const response = await Liink.getLeads(
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          ..._.pick(this.params, _.identity),
          skip: (page - 1) * this.params.take,
        }
      );

      this.response = response;
    } catch (err) {
      console.error(err);

      Notify.gebServerError("Error.");
    } finally {
      this.loading = false;
    }
  }

  clearSearch() {
    this.params = _.clone(this.defaultParams);
    this.getLeads(1);
  }

  sortLeads(params: any) {
    /*
    const ENDING = "ending";
    this.params.order = params.order
      ? `${params.prop}:${params.order.replace(ENDING, "")}`
      : this.defaultParams.order;
    */
    this.getLeads();
  }

  closeDialog(value: boolean) {
    this.report = value;
  }

  async load() {
    this.loading = true;

    try {
      this.banks = await Liink.getFinancialInstitution();
    } catch (err) {
      console.error(err);
    }

    try {
      this.employmentStatus = await Liink.getCatalog("employment-status");
    } catch (err) {
      console.error(err);
    }

    try {
      this.employmentTimeWorked = await Liink.getCatalog(
        "employment-time-worked"
      );
    } catch (err) {
      console.error(err);
    }

    try {
      this.cardTypes = await Liink.getCatalog("card-types");
    } catch (err) {
      console.error(err);
    }

    try {
      this.lenders = await Liink.getLenders();
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
  }

  created() {
    const index = "5.1";
    const title = "Leads";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Liink",
        to: "/liink",
      },
      {
        text: title,
      },
    ]);
    this.getLeads();
    this.load();
  }

  employmentStatusLabel(id: number) {
    const employmentStatus = this.employmentStatus.find(
      employmentStatus => employmentStatus.id == id
    );

    if (!employmentStatus) return "-";

    return employmentStatus.name;
  }

  employmentTimeWorkedLabel(id: number) {
    const employmentTimeWorked = this.employmentTimeWorked.find(
      employmentTimeWorked => employmentTimeWorked.id == id
    );

    if (!employmentTimeWorked) return "-";

    return employmentTimeWorked.name;
  }

  bankLabel(id: number) {
    const bank = this.banks.find(bank => bank.id == id);

    if (!bank) return "-";

    return bank.name;
  }

  cardTypeLabel(id: number) {
    const cardType = this.cardTypes.find(cardType => cardType.id == id);

    if (!cardType) return "-";

    return cardType.name;
  }

  async download() {
    try {
      const params = {
        ...this.params,
      };
      params.take = this.response ? this.response.count : 9999999999;
      const csv = await Liink.downloadLeads(params);

      const url = window.URL.createObjectURL(csv);
      const a = document.createElement("a");
      a.href = url;
      a.download = "leads.csv";
      a.click();
    } catch (err) {
      console.error(err);
    }
  }
}
